<template>
  <div class="flex-w-col">
    <LearnHeader
      bPath='/learn/csharp/6'
      fPath='/learn/csharp/8'
      title='Functions'
    />
  </div>
</template>

<script>
import LearnHeader from '@/components/LearnHeader.vue'

export default {
  components: { LearnHeader },
}
</script>
